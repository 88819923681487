import classes from './SupportLog.module.css';
import useLogQuery from 'util/useLogQuery';
import Accordian from 'components/Accordian';
import buildSupportLogTable from 'util/buildSupportLogTable';
import Table from 'components/Table';
import useFileData from 'util/useFileData';

function SupportLog(props) {
    const { log, logSettings } = props;

    const { data: currencyDefinitionsData, isLoading: currencyLoading } = useFileData('Game/CurrencyDefinitionSettings.json');
    const { data: qualityDefinitionsData, isLoading: qualityLoading } = useFileData('Game/QualitySettings.json');

    const { data: cardDefinitionsData, isLoading: cardsLoading  } = useFileData('Game/CardDefinitions_Season1.json');
    const { data: playCardDefinitionsData, isLoading: playsLoading  } = useFileData('Game/PlayCardDefinitions_Season1.json');
    const { data: equipmentDefinitionsData, isLoading: equipmentLoading } = useFileData('Game/EquipmentDefinitions_Season1.json');

    const { data, isLoading: queryLoading, error } = useLogQuery(log.query);

    const logType = log?.query?.find(inner => inner.Type === 'LogType.keyword');
    const settings = logSettings.find(setting => setting.LogType === logType.Value);

    const isLoading = queryLoading || currencyLoading || qualityLoading || cardsLoading || playsLoading || equipmentLoading;

    const currency = {};
    currencyDefinitionsData?.Currencies?.forEach((item, i) => {
        currency[item.CurrencyType] = item.CurrencyName;
    });

    const [headers, tableData] = isLoading ? [null, null] : buildSupportLogTable(data, settings?.Filters, currency, qualityDefinitionsData, cardDefinitionsData, playCardDefinitionsData, equipmentDefinitionsData);

    const label = log?.log;

    return (
        <Accordian
            className={classes.root}
            label={`${label} (${data?.length ?? 0})`}
            isLoading={isLoading}
        >
            {tableData?.length > 0 &&
                <Table
                    columns={headers}
                    data={tableData}
                    styles={{ maxHeight: '30rem' }}
                />
            }
        </Accordian>
    );
}

export default SupportLog;