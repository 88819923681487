import RewardLink from 'components/Table/RewardLink';
//TODO: Rework to use single file, all events should be able to use single buildEventsTable file for this data
export default function buildEventsTable(pointRewards, leaderboardRewards, store, showClaimed = false) {

    const pointHeaders = [
        {
            value: "points",
            label: "Points Required",
            type: "number",
        },
        {
            value: "rewardId",
            label: "Reward",
            type: "number",
            sortBy: "rewardIdValue"
        },
        {
            value: "rewardIdValue",
            type: "number",
            isSort: true
        },
        ...(showClaimed ? [{
            value: 'claimed',
            label: 'Claimed',
            type: 'string'
        }] : [])
    ];

    const leaderboardHeaders = [
        {
            value: "end",
            label: "Rank Requirement",
            type: "number",
        },
        {
            value: "rewardId",
            label: "Reward",
            type: "string",
            sortBy: "rewardIdValue"
        },
        {
            value: "rewardIdValue",
            type: "number",
            isSort: true
        },
        ...(showClaimed ? [{
            value: 'claimed',
            label: 'Claimed',
            type: 'string'
        }] : [])
    ];

    let pointData = [];
    let leaderboardData = [];
    if (pointRewards && leaderboardRewards) {
        pointRewards.forEach(point => {
            pointData.push({
                points: point.Points,
                rewardId: <RewardLink rewardId={point.RewardId} store={store} />,
                rewardIdValue: point.RewardId,
                claimed: point.IsClaimed ? 'True' : 'False'
            });
        });
        leaderboardRewards.forEach(leaderboard => {
            leaderboardData.push({
                start: leaderboard.Start,
                end: leaderboard.End,
                rewardId: <RewardLink rewardId={leaderboard.RewardId} store={store} />,
                rewardIdValue: leaderboard.RewardId,
                claimed: leaderboard.IsClaimed ? 'True' : 'False'
            });
        });
    }
    return [pointData, leaderboardData, pointHeaders, leaderboardHeaders];
}
