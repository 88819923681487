import React, { useEffect, useRef, useState, useCallback } from 'react';
import classes from './PlayerSearch.module.css';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import useValidateUser from 'util/useValidateUser';
import { toast } from 'react-hot-toast';
import onEnterKeyValidation from 'util/onEnterKeyWithValidation';

function PlayerSearch(props) {

    const {
        userSearch,
        setUserSearch,
        sharedOption = false,
        disabled = false,
        isLoading = false,
        noSearch = false,
        setParentValid = null, //Setter to check loading state in parent, if needed
        setParentValidPlayer = null, //Setter to inform parent if player was valid, if needed.
        mutate = null
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    const localRef = useRef(null);
    const [sharedSearch, setSharedSearch] = useState(sharedOption);
    const [validSearch, setValidSearch] = useState("");

    const setValidQuery = (input) => {
        setValidSearch(input);
        if (setParentValid) setParentValid(input); //Set parent so validation loading is visible
    }
  
    const debouncedValidate = useCallback(debounce(setValidQuery, 1000), []);
    const sharedId = searchParams.get("sharedId");

    const { data: validData, isLoading: validIsLoading } = useValidateUser(validSearch);
    //Using use effect here as parent state needs to be updated, and updating out of state would cause state to update each render
    useEffect(() => {
        if (sharedSearch && sharedId && sharedId !== "" && sharedId !== "undefined") {
            //Force refetch if query matches shared id
            localRef.current.value = sharedId;
            if (userSearch === sharedId && mutate) mutate();
            else {
                setUserSearch(sharedId);
            }
        } //Don't remove existing query when deselecting
    }, [sharedSearch, searchParams]);

    const playerSearch = () => {
        const localVal = localRef.current.value
        if (localVal === validSearch && localVal === userSearch && mutate) mutate(); //Force mutate on result if value matches on search
        setValidQuery(localVal);
    }

    let initialProps = {
        defaultValue: userSearch,
    };

    //TODO: loading indicator for validation if no search option?
    if (noSearch) {
        //If not using search button in component, then make input tightly controlled
        initialProps = {
            ...initialProps,
            onChange: e => debouncedValidate(e.target.value) //If not using manual search, debounce validate function
        };
    }

    useEffect(() => {
        const setValidPlayer = (input) => {
            if (setParentValidPlayer)
                setParentValidPlayer(input?.isValid)
        }
        if (validData?.isValid && !validIsLoading) {
            setUserSearch(validData?.userId.toString());
            toast.dismiss("validatePlayerError");
            setValidPlayer(validData);
        } else if (validData?.isValid === false) {
            toast.error(`Input Player ID ${validData.userId} does not match valid user`, {
                id: "validatePlayerError"
            });
            setValidPlayer(validData);
        }
    }, [validData]);

    return (
        <div className={classes.root}>
            <div className={classes.searchWrapper}>
                <TextInput
                    className={classes.input}
                    label="Player ID"
                    innerRef={localRef}
                    placeholder={"Enter Player ID"}
                    isLoading={isLoading}
                    disabled={sharedSearch}
                    onKeyDown={e => onEnterKeyValidation(e, playerSearch, !noSearch)}
                    {...initialProps}
                />
                {sharedOption &&
                    <div className={classes.sharedOption}>
                        <label>Use Shared ID
                            <input
                                type="checkbox"
                                checked={sharedSearch}
                                onChange={() => setSharedSearch(prev => !prev)}
                            />
                        </label>
                    </div>
                }
                {!noSearch && <Button
                    onClick={playerSearch}
                    text="Search"
                    isLoading={isLoading}
                    disabled={disabled}
                />}
            </div>
            {!noSearch && userSearch ? <h5 className={classes.current}>{`Current: ${userSearch}`}</h5> : null}
        </div>
    );
}
export default PlayerSearch;