import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import classes from "./NBARewind.module.css";
import DatePicker from "components/DatePicker"
import nbaRewindRequest, { SaveFileData } from '../util/nbaRewindRequest';
import { toast } from 'react-hot-toast';
import Accordian from 'components/Accordian';
import buildNBARewindTable from 'util/buildNBARewindTables/buildNBARewindTable';
import buildTopPlaysTable from 'util/buildNBARewindTables/buildTopPlaysTable';
import buildObjectivesTable from 'util/buildNBARewindTables/buildObjectivesTable';
import Loader from 'components/Loader';
import Button from 'components/Button';
import WeightEditButton from './NBARewind/WeightEditButton';
import { useSearchParams } from 'react-router-dom';

function NBARewind() {
    const [date, setDate] = useState(null);
    const [rewindData, setRewindData] = useState(null);
    const [gameData, setGameData] = useState(null);
    const [polling, setPolling] = useState(null);
    const [wait, setWaiting] = useState(false);
    const [editing, setEditing] = useState(false);
    const [newData, setNewData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [searchParams] = useSearchParams();

    const [headers, tableData] = buildNBARewindTable(rewindData);

    //Stop editing weights if new data fetched or selected game changes
    useEffect(() => {
        setEditing(false);
        if (rewindData) setNewData(structuredClone(rewindData));
    }, [gameData, rewindData]);


    const setDateField = async (dateInput,reset) => {
        setGameData(null);
        setRewindData(null);
        setDate(dateInput);
        setWaiting(true);
        await poll(dateInput, 0, -1, reset ?? false);
    }

    useEffect(() => {
        const paramDate = searchParams?.get("rewindDate");
        const presetDate = new Date(paramDate);
        if (paramDate && presetDate) {
            setDateField(presetDate);
        }
    }, [searchParams])

    const poll = async (initialDate, count, errorId,reset) => {
        try {

            const newResult = await nbaRewindRequest(initialDate, count > 0, errorId, reset);
            if (newResult.err) {
                toast.error(newResult.err);
                setWaiting(false);
            } else {
                setRewindData(JSON.parse(newResult.data));
                setWaiting(newResult.wait);
            }

            // Call the function again after 60 seconds (or your chosen interval)
            if (newResult.wait) {
                if (count > 1000) {
                    setWaiting(false);
                    toast.error("Error getting data");
                } else {
                    setPolling(setTimeout(function () { poll(initialDate, count + 1, newResult.errorId); }, 1000));
                }
            }
        } catch (error) {
            toast.error("Error getting data");
        }
    };

    // Call the polling function initially
    const onTableClick = (row) => {
        setGameData(row)
    }
    const resetData = async () => {
        setDateField(date,true);
    }
    const cancelPolling = () => {
        clearTimeout(polling);
        setWaiting(false);
    }
    const [topHeaders, topRows] = buildTopPlaysTable(gameData, editing, setNewData);

    const [objectiveHeaders, objectiveRows] = buildObjectivesTable(gameData?.Objectives);

    async function updateWeights() {
        //Update weight endpoint
        setLoading(true);
        const res = await SaveFileData(date, newData);
        //Use local data to update display, since request after save will fetch data before backend is updated
        if (res?.success) {
            setRewindData(newData);
            setGameData(prev => newData?.Games.find(game => game?.Id === prev?.Id));
        }
        setLoading(false);
    }

    const topAction = <WeightEditButton
        editing={editing}
        setEditing={setEditing}
        onSubmit={updateWeights}
        setNewData={setNewData}
        data={rewindData}
    />;

    return (
        <div className={classes.root } >
            <div className={classes.dateWrapper}><label>Date:</label> <DatePicker
                isRange={false}
                onChange={setDateField}
                value={date}
                disabled={wait}
                className={classes.datepicker}
            />
            </div>
            {wait || loading ?
                <div ><Loader /> {!loading && <Button onClick={cancelPolling} text="Cancel" />}
            </div> :
                rewindData &&
                    <>
                    {tableData.length > 0 ?
                        <Table
                            columns={headers}
                            data={tableData}
                            onClick={onTableClick }
                            styles={{ maxHeight: '30rem' }}
                        />
                        : 
                        <div>No games on this day</div>
                    }
                    {gameData &&
                        <div>
                            <h2 className={classes.title}>{gameData.Home.Name} ({gameData.HomePoints}) at {gameData.Away.Name} ({gameData.AwayPoints}) </h2>
                            <div className={classes.margins}>
                                <Accordian
                                    label={`Starting Lineup`}
                                >
                                    <div className={classes.flexdiv } >
                                        <div className={classes.flexinner }>
                                            <div className={classes.bold }>{gameData.Home.Name}</div>
                                        {gameData?.HomeStartingLineup?.map((item, index) => {
                                            return <div>{item.FullName}</div>
                                        })}
                                        </div>
                                        <div className={classes.flexinner }>
                                            <div className={classes.bold }>{gameData.Away.Name}</div>
                                        {gameData?.AwayStartingLineup?.map((item, index) => {
                                            return <div>{item.FullName}</div>
                                        })}
                                        </div>
                                    </div>
                                </Accordian>
                                {topRows ?
                                        <div className={classes.topPlays}>
                                        <Table
                                            columns={topHeaders}
                                            data={topRows}
                                            customActions={[topAction]}
                                            showPagination={false}
                                            hideDefaultActions={true}
                                        />
                                        </div>
                                    : 
                                       <div>No Highlights found</div>
                                }
                            </div>
                            {objectiveRows?.length > 0 && <Accordian label={`Objectives (${objectiveRows.length})`}>
                                <Table
                                    columns={objectiveHeaders}
                                    data={objectiveRows}
                                    showPagination={false}
                                    showActions={false}
                                />
                            </Accordian>}
                            {<div>
                                <Button onClick={resetData} text="Verify Data" />
                            </div>} 
                        </div>
                        }
                    </>
            }
        </div>
    );
}

export default NBARewind