import RewardLink from 'components/Table/RewardLink';
export default function buildEventsTable(locations) {

    const locationsHeaders = [
        {
            value: "PackId",
            label: "Pack ID",
            type: "number",
        },
        {
            value: "SavingsPerc",
            label: "Savings Percent",
            type: "number",
        },
        {
            value: "BuyAllCost",
            label: "Buy all cost",
            type: "number",
        },
    ];

    let locationsData = [];
    //TODO: pass in React element for clickable reward modal - sort/filter by reward value in separate column
    if (locations) {
        locations.forEach(loc => {
                locationsData.push({
                    PackId: <RewardLink rewardId={loc.PackId} store={100} />,
                    SavingsPerc: loc.SavingsPerc,
                    BuyAllCost: loc.BuyAllCost.CurrencyAmount
                });
        });
    }
    return [locationsData, locationsHeaders, locationsData, locationsHeaders];
}
