import { useEffect, useState } from 'react';
import classes from './SearchType.module.css';
import Select from 'components/Select';
import { components } from 'react-select';
import useAppTree from 'util/useAppTree';
import useStateData from 'util/useStateData';

function SearchType(props) {
    const { value, setValue } = props

    const { data: appTreeData, isLoading: appTreeLoading, isError: appTreeError, mutate: mutateAppTree } = useAppTree();

    const [isExpandedOptions, setIsExpandedOptions] = useState(false);

    const stateFilter = [ //TODO: Get list from endpoint? Params?
        'Inventory_QRCodes_MyState',
        'Inventory_DraftBoard_MyDraftPickBoard',
        'Inventory_Currency_MyCurrency',
        'Inventory_Cards_MomentumSlots',
        'Player_DailyLogin_LoginStateData',
        'Player_Player_ssPlayer',
        'Player_Player_MyPlayerState',
        'Player_AccountInfo_AccountInfo',
        'UserAccount_UserSession_ActiveSession',
        'UserAccount_UserSession_LastSentRequestInfo',
        'UserAccount_LoginStub_LoginStub',
        'UserAccount_UserAccountAnalytics_AccountAnalytics'
    ];

    const addMore = (inArray) => { //Set first option to be show more/less
        return inArray.toSpliced(0, 0,
        {
            value: 'more',
            label: "Show more",
        });
    }

    let options = isExpandedOptions ? addMore(appTreeData) : addMore(appTreeData.filter(item => stateFilter.includes(item.value)));
    //Set default value if options are avaliable
    useEffect(() => { //useEffect so statement doesn't fire until after component is rendered (Can't update state until this is rendered)
        if (value === null && options.length > 0) setValue(options?.find(option => option.value === "Player_Player_ssPlayer") ?? options?.[1] ?? null);
    }, [options])

    const selectOptions = ({children, ...rest }) => { //Override to add show more/less button as extra option
        if (rest.value === 'more') {
            return (
                <div
                    className={classes.showMore}
                    onClick={() => setIsExpandedOptions(prev => !prev)}>
                        {`Show ${isExpandedOptions ? "less" : "more"}`}
                    </div>
            );
        }
        return (
            <components.Option {...rest}>
                {children}
            </components.Option>
        );
    }

    return (
        <div className={classes.searchType}>
            {options.length > 0 &&
                <label> Search Type
                    <Select
                        className={classes.typeSelect}
                        components={{ Option: selectOptions }}
                        options={options}
                        onChange={val => setValue(val)}
                        value={options.find(option => option.value === value?.value)}
                    />
                </label>
            }
        </div>
    );
}

export default SearchType