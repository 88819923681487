import Tab from './Tab';
import classes from './Tabs.module.css';

function Tabs(props) {

    const { tabValues, selectedTab, setSelected } = props;

    return (
        <div className={classes.root}>
            {tabValues.map(tab =>
                <Tab key={`UserInfoTab-${tab.name}`} tab={tab} selectedTab={selectedTab} setSelected={setSelected} />
            )}
        </div>
    );
}

export default Tabs;