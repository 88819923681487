import classes from './JsonRow.module.css';
import { useContext } from 'react';
import { ModalContext } from 'constants/Contexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
function JsonRow(props) {
    const { setModalContent } = useContext(ModalContext);
    const { json, title, parent } = props;

    const download = () => {
        const name = `${parent?.Scheduled} ${parent?.Home?.Alias} vs ${parent?.Away?.Alias} - ${title}.json`
        const blob = new Blob([JSON.stringify(json)], {
            type: 'text/json'
        });
        const a = document.createElement('a');
        a.download = name;
        a.href = window.URL.createObjectURL(blob);
        const click = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(click);
        a.remove();
    };

    const openModal = () => {
        setModalContent({
            title: title,
            body: <pre>{JSON.stringify(json, null, 2)}</pre>
        });
    }

    return (
        <div className={classes.root}>
            <div
                className={classes.view}
                onClick={openModal}
                title={"View Json"}
            >
                {`View`}
            </div>
            <FontAwesomeIcon
                title={"Download Json"}
                icon={faDownload}
                className={classes.download}
                onClick={download}
            />
        </div>
    );
}

export default JsonRow;