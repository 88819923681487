import React, { useState } from 'react';
import classes from './UserCollection.module.css';
import Tabs from 'components/Tabs/Tabs';
import PlayerSearch from './UserInfo/PlayerSearch';
import usePlayerCollection from 'util/usePlayerCollection';
import Table from 'components/Table';
import buildCardListTable from 'util/buildUserCollectionTables/buildCardListTable';
import buildEquipmentTable from 'util/buildUserCollectionTables/buildEquipmentTable';
import buildMentorTable from 'util/buildUserCollectionTables/buildMentorTable';
import buildPlayListTable from 'util/buildUserCollectionTables/buildPlayListTable';
import buildCurrencyTable from 'util/buildUserCollectionTables/buildCurrencyTable';
import buildDeckTable from 'util/buildUserCollectionTables/buildDeckTable';
import Accordian from 'components/Accordian';
import useFileData from 'util/useFileData';
import Select from 'components/Select';
import UrlParamHandler from 'components/UrlParamHandler';

function UserCollection() {

    //TODO: Set shared search in url param? Need some shared param between tiles.
        //Local storage could work fine in this case
        //Session storage? (Confirm if this is per tab or for entire session)
    const tabValues = [
        { name: "cards", label: "Player Cards" },
        { name: "items", label: "Items" }
    ];

    const [userSearch, setUserSearch] = useState("");
    //TODO: Shared search should come from url params, presumably
    const [selectedTab, setSelectedTab] = useState(tabValues[0].name);

    const { data: cardDefinitionsData } = useFileData('Game/CardDefinitions_Season1.json');
    const { data: currencyDefinitionsData } = useFileData('Game/CurrencyDefinitionSettings.json');
    const { data: equipmentDefinitions } = useFileData('Game/EquipmentDefinitions_Season1.json');
    const { data: qualitySettingsData } = useFileData('Game/QualitySettings.json');
    const { data: playDefinitions } = useFileData('Game/PlayCardDefinitions_Season1.json');

    const { data: collection, isLoading: playerLoading, isError, mutate } = usePlayerCollection(userSearch);

    const [cardListHeaders, cardListInnerHeaders, cardListData] = buildCardListTable(collection?.Cards?.CardList, cardDefinitionsData, qualitySettingsData); //TODO: Make generic table formatter?
    const [playListHeaders, playListInnerHeaders, playListData] = buildPlayListTable(collection?.PlayCards?.PlayList, playDefinitions, qualitySettingsData);
    const [equipmentHeaders, equipmentData] = buildEquipmentTable(collection?.Equipment?.EquipmentList, equipmentDefinitions, qualitySettingsData);
    const [mentorHeaders, mentorData] = buildMentorTable(collection?.Mentors?.MentorList);
    const [currencyHeaders, currencyData] = buildCurrencyTable(collection?.Currencies?.CurrencyList, currencyDefinitionsData);
    const [deckHeaders, deckInnerHeaders, deckData] = buildDeckTable(collection?.Decks?.Decks, cardDefinitionsData);
    const deckOptions = [{
        label: 'All Cards',
        value: null
    }].concat(collection?.Decks?.Decks.map(deck => {
        return {
            label: `Deck ${deck.DefID} (${deck.OverallRating})`,
            value: deck.DefID
        };
    }));
    const [selectedDeck, setSelectedDeck] = useState(deckOptions[0]);

    const includesCardId = (cardGroup, card) => {
        return cardGroup.CardGroups.some(group => group.CardIDs.includes(card.id))
    };

    const cardTableData = selectedDeck?.value === null || selectedDeck?.value === undefined ? cardListData : cardListData.filter(card => {
        const deck = collection?.Decks?.Decks.find(deck => deck.DefID === selectedDeck.value);
        return includesCardId(deck.Offense, card) || includesCardId(deck.Defense, card) || includesCardId(deck.SpecialTeams, card)
    })

    return (
        <div className={classes.root}>
            <PlayerSearch
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                sharedOption={true}
                isLoading={playerLoading}
                mutate={mutate}
            />
            {
                collection?.Cards &&
                <div className={classes.collection}>
                    <Tabs
                        tabValues={tabValues}
                        selectedTab={selectedTab}
                        setSelected={setSelectedTab}
                    />
                    {selectedTab === tabValues[0].name ? 
                        <div
                            className={classes.accordiansWrapper}
                        >
                            <Accordian
                                label={`Player Cards (${cardListData.length})`}
                                defaultOpen={cardListData?.length > 0}
                            >
                                    {/*<label className={classes.deck}>
                                    <div>
                                        {`Deck`}
                                        <Select
                                            className={classes.deckSelect}
                                            options={deckOptions}
                                            value={selectedDeck}
                                            onChange={setSelectedDeck}
                                        />
                                    </div>
                                </label>*/}
                                {cardListData.length > 0 &&
                                    <Table
                                        columns={cardListHeaders}
                                        innerColumns={cardListInnerHeaders}
                                        data={cardTableData}
                                        hasChildren
                                        styles={{ maxHeight: '30rem' }}
                                    />}
                            </Accordian>
                            <Accordian
                                label={`Plays (${playListData.length})`}
                            >
                                {playListData.length > 0 &&
                                    <Table
                                        columns={playListHeaders}
                                        innerColumns={playListInnerHeaders}
                                        hasChildren
                                        data={playListData}
                                        styles={{ maxHeight: '30rem' }}
                                    />}
                            </Accordian>
                            <Accordian
                                label={`Mentors (${mentorData.length})`}
                            >
                                {mentorData.length > 0 &&
                                    <Table
                                        columns={mentorHeaders}
                                        data={mentorData}
                                        styles={{ maxHeight: '30rem' }}
                                    />}
                            </Accordian>
                        </div>   
                    : 
                        <div
                            className={classes.accordiansWrapper}
                        >
                            <Accordian
                                label={`Equipment (${equipmentData.length})`}
                                defaultOpen
                            >
                                {equipmentData.length > 0 &&
                                    <Table
                                        columns={equipmentHeaders}
                                        data={equipmentData}
                                        styles={{ maxHeight: '30rem' }}
                                    />}
                            </Accordian>
                            <Accordian
                                label={`Currencies (${currencyData.length})`}
                            >
                                {currencyData.length > 0 &&
                                    <Table
                                        columns={currencyHeaders}
                                        data={currencyData}
                                        styles={{ maxHeight: '30rem' }}
                                    />}
                            </Accordian>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default UserCollection;