export default function buildDeckTable(deckList, cardDefinitions) {
    const headers = [
        {
            value: "id",
            label: "ID",
            type: "number"
        },
        {
            value: "name",
            label: "Name",
            type: "string"
        },
        {
            value: "rating",
            label: "Rating",
            type: "number"
        },
        {
            value: "tierId",
            label: "Tier ID",
            type: "string"
        },
        {
            value: "tierPercent",
            label: "Tier %",
            type: "string"
        }
    ];

    const innerHeaders = [
        {
            value: 'id',
            label: 'Card ID',
            type: 'number'
        },
        {
            value: 'name',
            label: 'Card Name',
            type: 'string'
        },
        {
            value: 'position',
            label: 'Position Group',
            type: 'number'
        },
        {
            value: 'section',
            label: 'Section',
            type: 'number'
        },
        {
            value: 'sectionRating',
            label: 'sectionRating',
            type: 'number'
        }
    ];

    const data = [];
    if (deckList?.length > 0) {
        deckList.forEach(deck => {
            let nodes = [];
            deck.Offense.CardGroups.forEach(cardGroup => {
                cardGroup?.CardIDs?.forEach(cardId => {
                    const cardDef = cardDefinitions?.CardList.find(card => card.ID === cardId);
                    const node = {
                        id: cardId,
                        name: cardDef?.FirstName + " " + cardDef?.LastName,
                        position: cardGroup.PositionGroup,
                        section: deck.Offense.Section,
                        sectionRating: deck.Offense.SectionRating,
                    }
                    nodes.push(node);
                });
            });
            deck.Defense.CardGroups.forEach(cardGroup => {
                cardGroup?.CardIDs?.forEach(cardId => {
                    const cardDef = cardDefinitions?.CardList.find(card => card.ID === cardId);
                    const node = {
                        id: cardId,
                        name: cardDef?.FirstName + " " + cardDef?.LastName,
                        position: cardGroup.PositionGroup,
                        section: deck.Offense.Section,
                        sectionRating: deck.Offense.SectionRating,
                    }
                    nodes.push(node);
                });
            });
            deck.SpecialTeams.CardGroups.forEach(cardGroup => {
                cardGroup?.CardIDs?.forEach(cardId => {
                    const cardDef = cardDefinitions?.CardList.find(card => card.ID === cardId);
                    const node = {
                        id: cardId,
                        name: cardDef?.FirstName + " " + cardDef?.LastName,
                        position: cardGroup.PositionGroup,
                        section: deck.Offense.Section,
                        sectionRating: deck.Offense.SectionRating,
                    }
                    nodes.push(node);
                });
            });
            const deckList = {
                id: deck.DefID,
                name: deck.Name,
                rating: deck.OverallRating,
                tierId: deck.TierID,
                tierPercent: `${(deck.TierPercent * 100).toFixed(2)}%`,
                nodes
            };
            data.push(deckList);
        });
    }
    return [headers, innerHeaders, data];
}