import classes from './Loader.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Loader(props) {

    return (
        <div className={classes.root}>
            <FontAwesomeIcon
                icon={faSpinner}
                className={classes.spin}
            />
        </div>
    );
}

export default Loader;