import useSWR from 'swr';
import getUrl from 'util/getUrl';
import fetcher from 'util/fetcher';
import hasValue from 'util/hasValue';
import RewardLink from 'components/Table/RewardLink';

export default function useMailbox(userSearch, deleted = false) {
    const { data, error, isLoading, isValidating, mutate } = useSWR(hasValue(userSearch) ? `${getUrl()}/Mail/${deleted ? "GetDeletedMail" : "GetMailbox"}?lPlayerId=${userSearch}` : null, (...args) => fetcher(...args).then(res => res.json()), {
        revalidateOnFocus: false
    });

    return {
        data: data?.Packages?.map(mail => {
            const loot = JSON.parse(mail.Loot);
            const { StoreType, PackId } = loot;
            return {
                ...mail,
                 Created: (mail?.Created && new Date(mail.Created)?.toISOString()) ?? 'N/A',
                Loot: <RewardLink rewardId={PackId} store={StoreType} />,
                LootValue: PackId
            }
        }) ?? [],
        isLoading: isLoading || isValidating,
        isError: error,
        mutate: mutate
    }
}