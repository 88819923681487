export default function buildSupportLogTable(items, columns, currencies, qualities, cards, plays, equipment) {
    if (items.length > 0) {
        const headers = columns.flatMap(column => {
            const name = column.Name;
            const firstItem = items.find(item => item?.[name]); //Find first instance of column
            if (name === "currencyType") {
                const currencyColumn = {
                    value: name,
                    label: name,
                    type: "string",
                    sortBy: "currencyValue"
                };
                const currencySort = {
                    value: "currencyValue",
                    type: "number",
                    isSort: true
                }
                return [currencyColumn, currencySort]
            } else if (name === "qualityType") {
                const qualityColumn = {
                    value: name,
                    label: name,
                    type: "string",
                    sortBy: "qualityValue"
                };
                const qualitySort = {
                    value: "qualityValue",
                    type: "number",
                    isSort: true
                }
                return [qualityColumn, qualitySort]
            }
            return {
                value: name,
                label: name,
                type: typeof firstItem?.[name] ?? "string",
                filter: column?.Filter
            }
        });
        const data = [];
        items?.forEach((item, i) => {
            //Map node using headers object
            const node = {};
            headers.forEach(header => {
                const value = item[header.value];
                if (header.value === 'currencyType') {
                    node[header.value] = currencies[value];
                    node["currencyValue"] = value;
                }
                else if (header.value === "qualityType") {
                    node[header.value] = qualities[value];
                    node["qualityValue"] = value;
                }
                else if (header?.filter === "getCurrencyName" && currencies[value]) {
                    node[header.value] = currencies[value] + ` (${value})`;
                }
                else if (header?.value === "Name" && item?.DefId) {
                    if (item?.LogType.includes("ULOGainNFLPlayerCard")) {
                        const card = cards?.CardList?.find(val => val.ID === item?.DefId);
                        node[header.value] = card ? `${card?.FirstName} ${card?.LastName}` : "N/A";
                    } else if (item?.LogType.includes("ULOGainPlayCard")) {
                        const play = plays.PlayCardList.find(val => val?.ID === item?.DefId);
                        node[header.value] = play ? `${play.Name}` : "N/A"
                    } else if (item?.LogType.includes("ULOGainEquipment")) {
                        const equip = equipment.EquipmentList.find(val => val?.ID === item?.DefId);
                        node[header.value] = equip ? `${equip.Name}` : "N/A"
                    } else node[header.value] = item[header.value];
                }
                else {
                    node[header.value] = item[header.value];
                }
            });
            data.push(node);
        });
        return [headers, data];
    } return [];
}