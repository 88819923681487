import useSWR from 'swr';
import getUrl from 'util/getUrl';
import moment from 'moment';
import fetcher from 'util/fetcher'; 

export default function useStateData(state) {
    const { data, error, isLoading, isValidating, mutate } = useSWR(state?.state ? `${getUrl()}/ObjectEditor/GetData?id=0&app=${state?.app}&service=${state?.service}&state=${state.state}` : null, (...args) => fetcher(...args).then(res => res.json()));

    let jsonKeys = [];
    if (data?.Success) {
        const parsedJson = JSON.parse(data.Json);
        Object.keys(parsedJson).map(key => {
            const value = parsedJson[key]
            jsonKeys.push({
                value: key,
                label: key,
                isDate: value && typeof value === "string" && moment(value).isValid() ? true : false
            });
        });
    }
    return {
        data: jsonKeys,
        isLoading: isLoading || isValidating,
        isError: error,
        mutate,
    }
}