import { useState } from 'react';
import classes from './OtherSearch.module.css';
import Select from 'components/Select';
import BannedSearch from './OtherSearch/BannedSearch';
import LostSearch from './OtherSearch/LostSearch';
import Loader from 'components/Loader';
import UserSelect from 'tiles/UserInfo/UserSelect';

function OtherSearch(props) {

    const {
        userSearch,
        setUserSearch,
        advancedUsers,
        setAdvancedUsers
    } = props;

    const selectOptions = [
        {
            value: "banned",
            label: "Banned Users"
        },
        {
            value: "lost",
            label: "Lost Account"
        },
    ];

    const [type, setType] = useState(selectOptions[0])
    const [loading, setLoading] = useState(false);

    return (
        <div className={classes.root}>
            <label>Search Type
                <Select
                    options={selectOptions}
                    value={type}
                    onChange={val => setType(val)}
                />
            </label>
            {type.value === "banned" ?
                <BannedSearch /> :
             type.value === "lost" ?
                <LostSearch
                    loading={loading}
                    setLoading={setLoading}
                    setAdvancedUsers={setAdvancedUsers}
                    setUserSearch={setUserSearch}
                /> : ""
            }
            {loading ? <Loader /> :
                advancedUsers?.length > 0 && advancedUsers[0]?.err ? <div>{advancedUsers}</div> :
                        advancedUsers?.length > 0 ?
                            <UserSelect 
                                users={advancedUsers}
                                userSearch={userSearch}
                                setUserSearch={setUserSearch}
                            />
                : null
            }
        </div>
    );
}
export default OtherSearch;