import { objectiveType } from 'constants/NBARewindTypes';
export default function buildObjectivesTable(objectives) {
    const headers = [
        {
            value: "player",
            label: "Player",
            type: "string",
        },
        {
            value: "type",
            label: "Type",
            type: "string",
        },
        {
            value: "value",
            label: "Value",
            type: "number",
        },
    ];

    const data = objectives?.map(objective => {
        const { FeaturedPlayer, Type, Value, Weight } = objective;
        return {
            player: `${FeaturedPlayer?.FirstName} ${FeaturedPlayer?.LastName}`,
            type: `${objectiveType?.[Type] ?? 'N/A'} (${Type})`,
            value: Value
        }
    });

    return [headers, data];
}