import React from 'react';
import Table from 'components/Table';
import classes from "../GameInfo.module.css";
import buildPackTable from 'util/buildPackTable';

function Packs(props) {
    const {
        packs,
        isLoading
    } = props;

    const [packTableHeaders, packSettings] = BuildPackSettings(packs);
    function BuildPackSettings(packs) {
        let packList = [];
        let packHeaders = null; 
        packs.forEach((pack, i) => {
            const [headers, packData] = buildPackTable(pack);
            if (i === 0) packHeaders = headers; //Use first table for headers (main menu)
            packList = packList.concat(packData);
        });

        return [packHeaders, packList];
    }

    return (
        <div>
            {!isLoading &&
                <Table
                    columns={packTableHeaders}
                    data={packSettings}
                    styles={{ maxHeight: '25rem' }}
                />
            }
        </div>
    );
}

export default Packs