export default function buildCurrencyTable(currencyList, currencyDefinitions) {
    const headers = [
        {
            value: 'type',
            label: 'Type',
            type: 'number'
        },
        {
            value: 'name',
            label: 'Name',
            type: 'string'
        },
        {
            value: 'amount',
            label: "Amount",
            type: 'number'
        },
    ];
    const data = [];
    currencyList?.forEach((item, i) => {
        const currDef = currencyDefinitions.Currencies.find(def => def.CurrencyType === item.Type);
        const node = {
            type: item.Type,
            name: currDef?.CurrencyName ?? 'N/A',
            amount: item.TotalAmount,
        }
        data.push(node);
    })
    return [headers, data];
}