import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import Table from 'components/Table';
import classes from "../GameInfo.module.css";
import Loader from 'components/Loader';
import Select from 'components/Select';
import { toast } from 'react-hot-toast';

function Catalog(props) {
    const {
        cardsSettings,
        playsSettings,
        equipmentSettings,
        archetypeSettings
    } = props;
    const tableNames = [{ value: "CARDS", label: "Cards" }, { value: "PLAYS", label: "Plays" }, { value: "EQUIPMENT", label: "Equipment" }, { value: "ARCHETYPES", label: "Archetypes" }];
    const [selectedTable, setSelectedTable] = useState(tableNames[0]);
    const cardsTableHeaders = [
        { label: "Name", type: "string", value: "Name" },
        { label: "ID", type: "number", value: "ID" },
        { label: "Quality", type: "number", value: "Quality", sortBy: "QualityValue" },
        { label: "QualityValue", type: "number", value: "QualityValue", isSort: true },
        { label: "Team", type: "number", value: "Team", sortBy: "TeamValue" },
        { label: "TeamValue", type: "number", value: "TeamValue", isSort: true },
        { label: "Position", type: "string", value: "Position" },
        { label: "Synergy", type: "string", value: "Synergy", sortBy: "SynergyValue" },
        { label: "SynergyValue", type: "number", value: "SynergyValue", isSort: true },
        { label: "Archetype", type: "string", value: "Archetype", sortBy: "ArchetypeValue" },
        { label: "ArchetypeValue", type: "number", value: "ArchetypeValue", isSort: true },
        { label: "Stats", type: "string", value: "Stats" }
    ];

    const playsTableHeaders = [
        { label: "Name", type: "string", value: "Name" },
        { label: "ID", type: "number", value: "ID" },
        { label: "Quality", type: "number", value: "Quality", sortBy: "QualityValue" },
        { label: "QualityValue", type: "number", value: "QualityValue", isSort: true },
        { label: "Phase Type", type: "number", value: "PhaseType" },
        { label: "Play Type", type: "string", value: "PlayType" },
        { label: "Win Percent", type: "string", value: "WinPercent" },
        { label: "Yards Gained", type: "string", value: "YardsGained" },
        { label: "Yards Lost", type: "string", value: "YardsLost" }
    ]

    const equipmentTableHeaders = [
        { label: "Name", type: "string", value: "Name" },
        { label: "ID", type: "number", value: "ID" },
        { label: "Quality", type: "number", value: "Quality", sortBy: "QualityValue" },
        { label: "QualityValue", type: "number", value: "QualityValue", isSort: true },
        { label: "Stats", type: "string", value: "Stats" }
    ]
    const archetypeTableHeaders = [
        { label: "Name", type: "string", value: "Name" },
        { label: "ID", type: "number", value: "ID" },
        { label: "Stat1", type: "number", value: "Stat1" },
        { label: "Stat2", type: "number", value: "Stat2" },
        { label: "Stat3", type: "number", value: "Stat3" },
        { label: "Stat4", type: "number", value: "Stat4" }

    ]

    const isLoaded = cardsSettings?.length > 0 && playsSettings?.length > 0 && equipmentSettings?.length > 0 && archetypeSettings?.length > 0;
    return (
        <div>
            {isLoaded &&
                <div>
                    <div className={classes.selectDivIndex }>
                        <label>Table</label>
                        <Select
                            options={tableNames}
                            onChange={val => setSelectedTable(val)}
                            value={selectedTable}
                            className={`${classes.inline} ${classes.spacing_left}` }
                        />
                    </div>
                    <div>
                        {selectedTable.value === tableNames[0].value ?
                            <Table
                                columns={cardsTableHeaders}
                                data={cardsSettings}
                                styles={{ maxHeight: '29rem' }}
                            />
                            : selectedTable.value === tableNames[1].value ?
                                <Table
                                    columns={playsTableHeaders}
                                    data={playsSettings}
                                    styles={{ maxHeight: '29rem' }}
                                />
                                : selectedTable.value === tableNames[2].value ?
                                    <Table
                                        columns={equipmentTableHeaders}
                                        data={equipmentSettings}
                                        styles={{ maxHeight: '29rem' }}
                                    />
                                    : selectedTable.value === tableNames[3].value ?
                                        <Table
                                            columns={archetypeTableHeaders}
                                            data={archetypeSettings}
                                            styles={{ maxHeight: '29rem' }}
                                        />
                                : <label>No Data</label>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default Catalog