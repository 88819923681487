export default (field, value, boolean) =>  {
    const innerObj = {};
    const fieldName = `doc.${field}`;
    if (!boolean.includes("match")) { //Unique shape for range
        let rangeObject = {};
        if (boolean.includes("range")) {
            const [start, end] = value.split("&"); //Using & as split token for range
            rangeObject = {
                gt: start,
                lt: end
            }
        } else {
            rangeObject[boolean] = value; //Set up bool for gt/lt
        }
        innerObj[fieldName] = rangeObject;
    } else innerObj[fieldName] = value;
    const mainObj = {};
    const boolVal = boolean === "gt" || boolean === "lt" ? "range" : boolean; //gt and lt need range as the boolean type
    mainObj[boolVal] = innerObj;
    return mainObj;
}