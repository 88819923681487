import { mutate } from 'swr';
import getUrl from 'util/getUrl';

//Mutate all userInfo hooks
export default function mutatePlayer(playerId) {
    mutate(
        key => typeof key === 'string' && key.includes(`${getUrl()}/UserInfo`) && !key.includes('ValidateUser') && key.includes(playerId?.toString() ?? ""),
        undefined,
        { revalidate: true }
    );
}