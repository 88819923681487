import React, { useEffect, useState } from 'react';
import classes from "../UserMail.module.css";
import getUrl from 'util/getUrl';
import Button from 'components/Button';
import Select from 'components/Select';
import { toast } from 'react-hot-toast';
import fetcher from 'util/fetcher';
import useFileData from 'util/useFileData';

function SendMail(props) {
    const {
        playerId,
        massMailIds,
        isMassMail,
        mutate
    } = props;
    const initialMailPackage = {
        loot: "",
        subject: "",
        message: "",
        note: ""
    }
    const placeholders = {
        subject: "NFL Support",
        message: "Thank you for playing NFL Playmakers."
    }
    const mailTypes = [{ value: "STORE", label: "Store Pack" }];
    const storeTypes = [{ value: 0, label: "Main Menu Store" }, { value: 1, label: "Play Maker Packs" }, { value: 2, label: "Seasons Packs" }, { value: 4, label: "LTE Rush Packs" }, { value: 99, label: "BP Forward Packs" }, { value: 100, label: "BP Progression Packs" }, { value: 1000, label: "D2C" }, { value: 999, label: "Hard Currency" }];
    const [mailType, setMailType] = useState(mailTypes?.[0] ?? null);
    const [mailPackage, setMailPackage] = useState(initialMailPackage);
    const [clear, setClear] = useState(false);
    //TODO: Set selected store to update if Mail Types is updated/has more than 1 option
    const [selectedStore, setSelectedStore] = useState(storeTypes[0]);
    const [selectedPack, setSelectedPack] = useState();
    const { data: mainMenuPackStore } = useFileData('Game/MainMenuPackSettings.json');
    const { data: seasonPackStore } = useFileData('Game/SeasonsPackSettings.json');
    const { data: playmakersPackStore } = useFileData('Game/PlaymakersPackSettings.json');
    const { data: directToConsumerPackStore } = useFileData('Game/DirectToConsumerPackSettings.json')
    const { data: hardCurrencyPackStore } = useFileData('Game/HardCurrencyPackSettings.json')
    const { data: lteRushPackStore } = useFileData('Game/LTERushPackSettings.json');
    const { data: bpForwardPackStore } = useFileData('Game/BPForwardPackSettings.json');
    const { data: bpProgressionPackStore } = useFileData('Game/BPProgressionPackSettings.json');

    const stores = {
        0: mainMenuPackStore,
        1: playmakersPackStore,
        2: seasonPackStore,
        4: lteRushPackStore,
        99: bpForwardPackStore,
        100: bpProgressionPackStore,
        1000: directToConsumerPackStore,
        999: hardCurrencyPackStore
    };

    const buildPack = (packStore) => {
        return packStore?.Packs?.map(pack => { return { value: pack.Id, label: `(${pack.Id}) ${pack.AnalyticName}` }; });
    }

    const packSelect = buildPack(stores[selectedStore?.value]) ?? [];

    useEffect(() => {
        if (mainMenuPackStore?.Packs?.length > 0) {
            const packs = buildPack(mainMenuPackStore);
            setSelectedPack(packs[0]);
        }
    }, [mainMenuPackStore]);

    async function SendMail() {
        var lootString = JSON.stringify({
            StoreType: selectedStore?.value ?? -1,
            PackId: selectedPack?.value ?? -1,
            Key: ""
        });
        var { subject, message, loot, note } = mailPackage;
        if (!subject) {
            subject = placeholders.subject;
        }
        if (!message) {
            message = placeholders.message;
        }
        try {
            let body = null;
            var url = `${getUrl()}/Mail/SendMail?lPlayerId=${playerId}&packageType=${201}&subject=${subject}&message=${message}&messageArgs=${null}&loot=${lootString}&note=${note}`
            if (isMassMail) {
                body = {
                    szPlayerIds: massMailIds,
                    packageType: 201,
                    subject: subject,
                    message: message,
                    loot: lootString,
                    note: note
                };
                url = `${getUrl()}/Mail/SendMassMail`
            }
            const res = await fetcher(url, {
                method: 'POST',
                xhrFields: { withCredentials: true },
                ...(body && {
                    headers: {
                        "Content-Type": 'application/json'
                    }
                }),
                ...(body && { body: JSON.stringify(body) })
            });
            const data = await res?.json();
            //Handle error from endpoint
            if (!data) {
                setMailPackage(initialMailPackage);
                toast.error("An error occured while sending mail.");
                console.error("An error occured while sending mail."); //for debugging
            } else {
                if (clear) {
                    setMailPackage(initialMailPackage);
                }
                mutate();
                toast.success("Mail successfully sent");
            }
        }
        catch(error) {
            setMailPackage(initialMailPackage);
            toast.error("An error occured while sending mail.");
            console.error("An error occured while sending mail.", error); //for debugging
        }
    }
    const validatePlayers = !isMassMail || (isMassMail && massMailIds);
    const validMail = playerId && validatePlayers && (mailType?.value === mailTypes?.[0]?.value ? (selectedStore?.value >= 0 && selectedPack?.value >= 0) : true);

    return (
        <div>
            <div className={classes.spacing}>
                <label className={classes.label}> Mail Type</label>
                <Select
                    options={mailTypes}
                    onChange={val => setMailType(val)}
                    value={mailType}
                    className={classes.inline}
                    index={9}
                />
                <div className={classes.spacing_top}>
                    <label className={classes.label}>Store Type</label>
                    <Select
                        options={storeTypes}
                        value={selectedStore}
                        className={classes.inline}
                        onChange={val => {
                            setSelectedStore(val)
                            const packs = buildPack(stores[val?.value]);
                            setSelectedPack(packs?.[0]);
                        }}
                        index={8}
                    />
                    <label className={`${classes.spacing_right} ${classes.spacing_left}`}>Pack ID</label>
                    <Select
                        className={classes.inline}
                        options={packSelect}
                        value={selectedPack}
                        onChange={val => setSelectedPack(val)}
                        index={8}
                    />
                </div> 
                
            </div>
            <div className={classes.spacing}>
                <label className={classes.label}>Subject</label>
                <input
                    type="text"
                    placeholder={placeholders.subject}
                    value={mailPackage.subject}
                    onChange={(e) => setMailPackage({ ...mailPackage, subject: e.target.value })}
                />
            </div>
            <div className={classes.spacing}>
                <label className={classes.label}>Message</label>
                <textarea
                    className={classes.message}
                    placeholder={placeholders.message}
                    value={mailPackage.message}
                    onChange={(e) => setMailPackage({ ...mailPackage, message: e.target.value })}
                />
            </div>
            <div className={classes.spacing}>
                <label className={classes.label}>Note</label>
                <input
                    className={classes.note}
                    type="text"
                    placeholder="Note"
                    value={mailPackage.note}
                    onChange={(e) =>setMailPackage({ ...mailPackage, note: e.target.value })}
                />
            </div>
            <div className={classes.spacing}>
                <label className={classes.max_width}><input type="checkbox" onClick={(e) => setClear(e.target.checked)} className={classes.margins }></input>Clear on Send</label>
            </div>
            <div className={classes.spacing}>
                <Button onClick={SendMail} text="Send" disabled={!validMail}></Button>
            </div>
        </div>
    );
}
export default SendMail;