import { useState, useEffect } from 'react';
import classes from './LostSearch.module.css';
import TextInput from 'components/TextInput';
import Select from 'components/Select';
import Button from 'components/Button';
import DateRange from 'components/DatePicker';
import countryCodes from 'constants/countryCodes';
import lostUserSearchRequest from 'util/lostUserSearchRequest';
import { toast } from 'react-hot-toast';
import onEnterKeyValidation from 'util/onEnterKeyWithValidation';

function LostSearch(props) {
    const { loading, setLoading, setAdvancedUsers, setUserSearch } = props;

    const [name, setName] = useState("");
    const [exactName, setExactName] = useState(false);
    //Start as null option
    const [countryCode, setCountryCode] = useState(countryCodes[0]);
    const [deviceType, setDeviceType] = useState("");
    const [creationRange, setCreationRange] = useState([null, null]);

    const findLostPlayer = async () => {
        setLoading(true);
        const searchResult = await lostUserSearchRequest({
            playerName: name,
            teamName: "",//TODO: Remove from backend shape? Make optional?
            countryCode: countryCode.value,
            deviceType: deviceType,
            exactMatch: exactName,
            creationDateStart: creationRange?.[0],
            creationDateEnd: creationRange?.[1],
        });
        //TODO: Move display/error handling pattern for request to separate function?
        if (searchResult.length > 0 && !searchResult[0]?.err) {
            setAdvancedUsers(searchResult);
            setUserSearch(searchResult[0].value);
        }
        else if (searchResult[0]?.err) {
            toast.error(searchResult[0].err);
        }
        else {
            toast.success("No results found for lost search");
        }
        setLoading(false);
    }

    //Count of fields that are populated
    const multipleFields = [name, countryCode.value, deviceType, creationRange?.[0]].filter(value => !!value).length;

    const canSearch = Boolean(name && name !== "" && multipleFields >= 1);

    const enterKey = (e) => {
        onEnterKeyValidation(e, findLostPlayer, canSearch);
    }

    useEffect(() => {
        document.addEventListener("keydown", enterKey);
        return () => document.removeEventListener("keydown", enterKey);
    }, [canSearch]);

    return (
        <div className={classes.root}
        >
            <div className={classes.form}>
                <TextInput
                    label="Player Name (required)"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <label> Exact name match
                    <input type="checkbox" checked={exactName} onChange={e => setExactName(prev => !prev)} />
                </label>
                {/*TODO: Team name search?*/}
                <label> Country Code
                    <Select
                        options={countryCodes}
                        value={countryCode}
                        onChange={val => setCountryCode(val)}
                    />
                </label>
                <TextInput
                    label="Device Type"
                    value={deviceType}
                    onChange={e => setDeviceType(e.target.value)}
                />
                <label> Creation Date (Between)
                    <DateRange
                        isRange={true}
                        value={creationRange}
                        onChange={setCreationRange}
                        className={classes.lostDate}
                    />
                </label>
            </div>
            <Button
                text="Search"
                onClick={findLostPlayer}
                disabled={!canSearch}
            />
        </div>
    );
}

export default LostSearch;