import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
//Need to handle url params inside router, but want to recieve w/o needing PlayerSearch
export default function UrlParamHandler() {
    const [searchParams, setSearchParams] = useSearchParams();
    function updateSharedId(e) {
        const data = e?.data;
        if (data?.[0]?.includes("sharedId")) {
            const [game, env, tab] = data[0]?.split('-');
            if (searchParams.get('game') === game &&
                searchParams.get('tab') === tab &&
                searchParams.get('environment') === env
            ) {
                setSearchParams(params => {
                    params.set("sharedId", data[1]);
                    return params;
                });
            }
        }
    }

    useEffect(() => {
        window.addEventListener("message", updateSharedId);
        return () => {
            window.removeEventListener("message", updateSharedId);
        }
    }, []);

    return null
}