import classes from './Toaster.module.css';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import Button from 'components/Button';

function ReactToaster(props) {
    return (
        <Toaster
            position={"bottom-center"}
            toastOptions={{
                duration: Infinity
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ icon, message }) => (
                        <>
                            {icon}
                            {message}
                            {t.type !== 'loading' && (
                                <Button
                                    onClick={() => toast.dismiss(t.id)}
                                    text={"Dismiss"}
                                />
                            )}
                        </>
                    )}
                </ToastBar>
            )}
        </Toaster>
    );
}

export default ReactToaster;
