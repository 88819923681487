import { useState } from 'react';
import classes from './BannedSearch.module.css';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import onEnterKeyValidation from 'util/onEnterKeyWithValidation';
function BannedSearch() {
    const [search, setSearch] = useState("");

    const findBannedPlayer = () => {
        console.log('find player ', )
    };

    return (
        <div className={classes.root}>
            <TextInput
                className={classes.input}
                label="Banned Player Name"
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyDown={e => onEnterKeyValidation(e, findBannedPlayer, true)}
            />
            <Button
                text="Search"
                onClick={findBannedPlayer}
                disabled={!search}
            />
        </div>
    );
}

export default BannedSearch;