export const fields = [
    {
        value: "CDID",
        label: "CDID",
    },
    {
        value: "StateType",
        label: "StateType",
    },
    {
        value: "Service",
        label: "Service",
    },
    {
        value: "Module",
        label: "Module",
    },
    {
        value: "CreationDate",
        label: "CreationDate",
    },
];

export const booleans = [
    {
        value: "match_phrase",
        label: "Must Match",
    },
    {
        value: "not match_phrase",
        label: "Must Not Match",
    },
    {
        value: "range",
        label: "Must Fit In Range",
    },
    {
        value: "not range",
        label: "Must Not Fit In Range",
    },
    {
        value: "gt",
        label: "Must Be Greater Than",
    },
    {
        value: "lt",
        label: "Must Be Less Than",
    },
];

export const elasticTypes = [
    "Service",
    "Module",
    "StateSource"
];
