import classes from './EventDetail.module.css';

function EventDetail(props) {
    const { detail } = props;

    return (
        <label className={classes.label}> {`${detail.label}: `}
            <span className={classes.value}>
                {detail.value}
            </span>
        </label>
    );
}

export default EventDetail;