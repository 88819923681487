import RewardLink from 'components/Table/RewardLink';
export default function buildEventsTable(board, store) {

    const boardHeaders = [
        {
            value: "round",
            label: "Round",
            type: "number",
        },
        {
            value: "isBonusRound",
            label: "Bonus Round",
            type: "string"
        }
    ];

    const innerHeaders = [
        {
            value: "rewardId",
            label: "Reward ID",
            type: "number",
        },
        {
            value: "gameOver",
            label: "Game Over",
            type: "string",
        },
    ]

    let boardData = [];
    if (board) {
        board?.AllRounds?.forEach((round, i) => {
            const nodes = [];
            round.Reveals.forEach(reveal => {
                const { Reward, IsGameOver } = reveal;
                nodes.push({
                    rewardId: !IsGameOver ? <RewardLink rewardId={Reward} store={store} /> : 'N/A',
                    gameOver: IsGameOver.toString()
                });
            });
            boardData.push({
                id: i,
                round: i + 1,
                isBonusRound: round.IsBonusRound.toString(),
                nodes
            });
        });
    }
    return [boardData, boardHeaders, innerHeaders];
}
