import classes from './CurrentFilter.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

function CurrentFilter(props) {

    const { filter, setFilters } = props;

    const removeFilter = () => {
        setFilters(prev => {
            const i = prev.findIndex(item =>
                item.field === filter.field
                && item.boolean.value === filter.boolean.value
                && item.input == filter.input);
            return prev.toSpliced(i, 1);
        })
    }

    return (
        <div className={classes.root}>
            <FontAwesomeIcon
                icon={faX}
                size="lg"
                className={classes.icon}
                onClick={removeFilter}
            />
            <div className={classes.text}>
                <div>{filter.field}</div>
                <div>{filter.boolean.label}</div>
                <div>{filter.input.replace("&", " to ") /*& is range split token*/}</div>
            </div>
        </div>
    );
}

export default CurrentFilter;