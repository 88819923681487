export default function parseAppTree(data) { //Edited from main bobcat project
    const stateSelect = [];
    data?.forEach((item) => {
        var svc = item.children;
        for (var i = 0; i < svc.length; ++i) {
            var state = svc[i].children
            for (var j = 0; j < state.length; ++j) {
                var name = item.text + '_' + svc[i].text + '_' + state[j].text;
                const newOption = {
                    label: name,
                    value: name,
                    app: item.text,
                    service: svc[i].text,
                    state: state[j].text,
                }
                stateSelect.push(newOption);
            }
        }
    });
    return stateSelect;
}