import { useState } from 'react';
import classes from "../UserMail.module.css";
import Button from 'components/Button';
import fetcher from 'util/fetcher';
import getUrl from 'util/getUrl';
import { toast } from 'react-hot-toast';

function MailFeedback(props) {
    //TODO: Include basic textarea for comment/request
    //Send button
    //Set up backend to add send endpoint for slack messaging

    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    async function sendRequest() {
        setIsLoading(true);
        const response = await fetcher(`${getUrl()}/Mail/SendFeedback`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(
                message
            )
        });
        const res = await response.json();
        if (res?.Success) {
            toast.success("Pack request successfully sent to #cd-phoenix-cx");
            setMessage("");
        } else {
            toast.error("Error sending pack request to #cd-phoenix-cx");
        }
        setIsLoading(false);
    }

    return (
        <div className={classes.mailFeedback}>
            <h5>{`Send message to #cd-phoenix-cx slack requesting a new pack`}</h5>
            <textarea
                value={message}
                onChange={e => setMessage(e.target.value)}
            />
            <Button
                text={"Submit"}
                onClick={sendRequest}
                disabled={message?.length === 0}
                isLoading={isLoading}
            />
        </div>
    );
}

export default MailFeedback;