export const highlightType = [
    "",
    "Points Player", // 1
    "Buzzer Beater", // 2
    "Point Player 3 Point", // 3
    "Triple Double", // 4
    "Points Run", // 5
    "Three Point Players", // 6
    "Assists", // 7
    "Game Sealer", // 8
    "Barn Burner", // 9
    "2 for 1", // 10
    "3-pts Teammates", // 11 
    "Points Teammates (3)", // 12
    "Points Teammates (2)", // 13
    "3-pts Percent", // 14
    "3-pts Combined", // 15
    "Points Player + Dunk", // 16
    "Points Player + Rebound", // 17
    "Points Combined", //18 
    "Points Team", // 19
    "3-pts Perfect", // 20 
    "Answered Prayer" // 21 
];

export const objectiveType = [
    "N/A",
    "Points", //1
    "TwoPointers", //2
    "ThreePointers", //3
    "Assists", //4
    "Steals", //5
    "Rebounds", //6
    "Dunks", //7
    "PointsInPaint" //8
]
