import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal'
import { ModalContext } from 'constants/Contexts';
import Toaster from 'components/Toaster';
import GameImage from 'components/GameImage';
import UrlParamHandler from 'components/UrlParamHandler';

function Layout(props) {
    //TODO: Update so game name is important for image
    const { game = null } = props;
    const displayName = Layout.name;
    const [modalContent, setModalContent] = useState(null);

    return (
        <ModalContext.Provider value={{modalContent, setModalContent}}>
              {modalContent?.body && modalContent?.title &&
                    <Modal
                        modalContent={modalContent}
                        setModalContent={setModalContent}
                    />
              }
                {game &&
                    <>
                        <GameImage game={game} />
                        <UrlParamHandler />
                    </>
                }
              {props.children}
              <Toaster />
        </ModalContext.Provider>
    );
}

export default Layout;
