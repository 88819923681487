import React from 'react';
import classes from './Button.module.css';
import Loader from 'components/Loader';

function Button(props) {
    const { text, onClick, isLoading, disabled = false, className = null } = props;

    const isDisabled = isLoading || disabled;

    return (
        <button
            className={`${classes.root} ${isDisabled ? classes.disabled : ''} ${className ? className : ''}`}
            onClick={onClick}
            disabled={isDisabled}
        >
            {isLoading ? <Loader /> : text ?? ""}
        </button>
    )
}

export default Button;