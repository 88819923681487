import { useContext, useState, useEffect } from 'react';
import classes from './SearchResults.module.css';
import ReadText from 'components/ReadText';
import Accordian from 'components/Accordian';
import Note from 'components/Note';
import AddNote from 'components/AddNote'
import usePlayer from 'util/usePlayer';
import usePlayerLoginStub from 'util/usePlayerLoginStub';
import usePlayerAllStubs from 'util/usePlayerAllStubs';
import usePlayerBanStatus from 'util/usePlayerBanStatus';
import usePlayerBanHistory from 'util/usePlayerBanHistory';
import usePlayerNotes from 'util/usePlayerNotes';
import mutate from 'util/mutatePlayer';
import { ModalContext } from 'constants/Contexts';
import Table from 'components/Table';
import LoginStubs from 'components/LoginStubs';
import getUrl from 'util/getUrl';
import Loader from 'components/Loader';
import BanModal from './BanModal';
import fetcher from 'util/fetcher';

function SearchResults(props) {

    const { playerId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const { data: playerData } = usePlayer(playerId);
    const { data: loginStubData } = usePlayerLoginStub(playerId);
    const { data: allLoginStubs, mutate: mutateAllStubs } = usePlayerAllStubs(playerId);
    const { data: banHistoryData } = usePlayerBanHistory(playerId);
    const { data: banStatusData } = usePlayerBanStatus(playerId);
    const { data: notesData, mutate: mutateNotes } = usePlayerNotes(playerId);
    const [ purchaseData, setPurchaseData ] = useState([]);
    const { modalContent, setModalContent } = useContext(ModalContext);

    const displayBanHistory = (banHistoryData) => {
        //TODO: Update columns to better reflect types of ban / all useful data
        const columns = [
            {
                value: 'type',
                label: 'Ban Type',
                type: 'string'
            },
            {
                value: 'count',
                label: 'Number of Bans',
                type: 'number'
            },
            {
                value: 'lastBan',
                label: 'Last Ban Time',
                type: 'string'
            },
            {
                value: 'until',
                label: 'Banned Until',
                type: 'string'
            }
        ];
        const data = [];

        const { vpBans, botBans } = banHistoryData;
        //TODO: Displaying bot ban mock for basic table data, will need to revise
        data.push({
            type: 'Voided Purchase Ban',
            count: vpBans.NumberOfBans,
            lastBan: vpBans?.DateBanned ?? 'N/A',
            until: vpBans?.BannedUntil ?? 'N/A',
        });

        data.push({
            type: 'Bot Ban',
            count: botBans.NumberOfBans,
            lastBan: botBans?.DateBanned ?? 'N/A',
            until: botBans?.BannedUntil ?? 'N/A',
        });

        setModalContent({
            title: "Ban History",
            body: <Table
                columns={columns}
                data={data}
            />
        })
    }

    const displayBanModal = () => {
        setModalContent({
            title: "Ban User",
            body: <BanModal
                setModalContent={setModalContent}
                mutate={() => mutate(playerData.CDID)}
                CDID={playerData.CDID}
                banStatus={banStatusData?.banned}
            />
        })
    }

    const unban = async () => {
        setIsLoading(true);
        await fetcher(`${getUrl()}/UserInfo/UnbanUser?userId=${playerData.CDID}`, { method: 'POST' });
        mutate(playerData.CDID);
        setIsLoading(false);
        setModalContent(null)
    }
    const purchaseHeaders = [
        { label: "Timestamp Redeemed", type: "string", value: "TimestampRedeemed" },
        { label: "SKU", type: "string", value: "SKU" },
        { label: "Store", type: "string", value: "Store" },
        { label: "Quantity", type: "string", value: "Quantity" },
        { label: "Receipt", type: "string", value: "Receipt" },
        { label: "PurchaseUUID", type: "string", value: "PurchaseUUID" },
        { label: "MD5", type: "string", value: "MD5" }];
    async function getPurchaseData(id) {
        var response = await fetcher(`${getUrl()}/UserInfo/GetPurchaseInfo?playerId=${id}`, { method: 'POST' });
        var purchases = await response.json();
        var tempData = []
        if (purchases?.purchases) {
            for (var item in purchases.purchases) {
                tempData.push(purchases.purchases[item]);
            }
        }
        setPurchaseData(tempData);
    }

    useEffect(() => {
        getPurchaseData(playerId);
    }, [playerId]);

    //TODO: Remove from leaderboard option?
    //TODO: Record ban option?
    const userStatus = banStatusData ?
        banStatusData?.banned === false ? "No Ban" : "Ban"
        : null;

    const prioritySort = (noteA, noteB) => {
        if (noteA.Priority && !noteB.Priority) return -1
        else if (!noteA.Priority && noteB.Priority) return 1
        else {
            const dateA = Date.parse(noteA.Created);
            const dateB = Date.parse(noteB.Created);
            if (dateA > dateB) return -1;
            else if (dateB > dateA) return 1;
        }
        return 0;
    }

    const updateName = async (name) => {
        setIsLoading(true);
        await fetcher(`${getUrl()}/UserInfo/UpdatePlayerName?userId=${playerData.CDID}&playerName=${name}`, {method: 'POST'});
        mutate(playerData.CDID);
        setIsLoading(false);
    }

    if (playerData?.Profile) {
        var prevDate = Date.parse(playerData.AccountInfo.PreviousLoginData);
        var currentDate = Date.parse(playerData.AccountInfo.CurrentLoginData);
        var lastDate = prevDate > currentDate ? playerData.AccountInfo.PreviousLoginData : playerData.AccountInfo.CurrentLoginData;
        return (
            <div className={classes.userResultsWrapper}>
                <div className={classes.textFieldResults}>
                    <ReadText
                        label={"Player Name"}
                        value={playerData.Profile.PlayerName}
                        canEdit={true}
                        editName={"Rename"}
                        update={updateName}
                    />
                    <ReadText
                        label={"Player Id"}
                        value={playerData.CDID}
                    />
                    <ReadText
                        label={"Date Created"}
                        value={playerData.AccountInfo.CreationDate}
                    />
                    <ReadText
                        label={"Last Login Day"}
                        value={lastDate}
                    />
                    <ReadText
                        label={"Last Login Stub"}
                        value={loginStubData?.[0] ?? 'N/A'}
                    />
                    <ReadText
                        label={"Country Code"}
                        value={playerData.AccountInfo.CountryCode}
                    />
                    <ReadText
                        label={"Device Type"}
                        value={playerData.AccountInfo.Platform}
                    />
                    <ReadText
                        label={"Partner"}
                        value={playerData?.Profile?.isPartner}
                        boolView
                        actions={[{
                            text: banStatusData?.banned ? "Remove Partner" : "Make Partner",
                            action: () => {
                                console.log('Update Partner'); //TODO: Call func to update ban status
                                //TODO: Why does partner update ban?
                                mutate(playerData.CDID);
                            }
                        }]}
                        isLoading={isLoading}
                    />
                    <ReadText
                        label={"User Status"}
                        value={userStatus}
                    />
                    <ReadText
                        label={"Ban"}
                        value={banStatusData?.banned}
                        actions={[{
                            text: "Ban",
                            action: () => {
                                displayBanModal();
                            }
                        },
                        ...banStatusData?.banned ? [{
                            text: "Unban",
                            action: () => {
                                unban();
                            }
                        }] : [],
                        {
                            text: "Ban History",
                            action: () => displayBanHistory(banHistoryData)
                        }]}
                        isLoading={isLoading}
                    />
                    <ReadText
                        label={"Ban Until"}
                        value={banStatusData?.until ?? 'N/A'}
                    />
                    <Accordian
                        label={"Login Stubs"}
                    >
                        <LoginStubs
                            stubs={allLoginStubs?.stubs ?? []}
                            userId={playerData.CDID}
                            mutate={mutateAllStubs}
                        />
                    </Accordian>
                </div>
                <Accordian
                    label={`Notes (${notesData?.data?.length ?? '0'}) ${!!notesData?.data?.find(note => note.Priority > -1) ? '!' : ""}`}
                >
                    <div className={classes.notesWrapper}>
                        {
                            notesData?.data?.sort(prioritySort).map(note =>
                                <Note
                                    key={`note-${note.Created}`}
                                    note={note}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    userId={playerData.CDID}
                                    mutate={() => mutateNotes()}
                                    notes={notesData.data}
                                /> //TODO: Add section for writing new notes (w/ high pri option)
                            )
                        }
                        <AddNote
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            mutate={() => mutateNotes()}
                            playerId={playerData.CDID}
                            notes={notesData?.data}
                        />
                    </div>
                </Accordian>
                <Accordian
                    label={`Purchases (${purchaseData?.length ?? '0'})`}
                >
                    <div className={classes.purchaseWrapper}>
                        {
                            <Table
                                columns={purchaseHeaders}
                                data={purchaseData}
                                styles={{ maxHeight: '30rem' }}
                            />
                        }
                    </div>
                </Accordian>
            </div>
        );
    } else return null;
}
export default SearchResults;