export default function buildNBARewindTable(rewind) {
    const headers = [
        {
            value: "time",
            label: "Start Time",
            type: "string"
        },
        {
            value: "HomeName",
            label: "Home",
            type: "string"
        },
        {
            value: "HomePoints",
            label: "Home Points",
            type: "number"
        },
        {
            value: "AwayName",
            label: "Away",
            type: "string"
        },
        {
            value: "AwayPoints",
            label: "Away Points",
            type: "number"
        }];

    const table = [];
    rewind?.Games?.forEach(game => {
        game.HomeName = game.Home.Name;
        game.AwayName = game.Away.Name;
        var date = new Date(game.Scheduled);
        game.time = date.toLocaleTimeString() + "   " + date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        table.push(game);
    });
    return [headers, table];
}