import classes from './UserSelect.module.css';
import Select from 'components/Select';

function UserSelect(props) {
    const { users, userSearch, setUserSearch } = props;
    return (
        <label className={classes.userSelectLabel}> {`Users Found (${users.length})`}
            <Select
                className={classes.userSelect}
                options={users}
                value={users.find(result => userSearch === result.value)}
                onChange={user => setUserSearch(user.value)}
                index={8}
            />
        </label>
    )
}
export default UserSelect