import classes from "./DatePicker.module.css";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from 'react-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

function MyDatePicker(props) {
    const { isRange, className = null, ...rest } = props //value, onChange in ..rest from props

    const args = {
        className: classes.datePicker + className ? " " + className : "",
        calendarIcon: null,
        clearIcon: <FontAwesomeIcon
            icon={faX}
            className={classes.x}
            size={"xs"}
        />,
        ...rest,
    }

    if (isRange) {
        return (
            <DateRangePicker
                rangeDivider={null}
                {...args}
            />
        );
    } else {
        return (
            <DatePicker
                {...args}
            />
        );
    }
}

export default MyDatePicker;