const Special = [ //TODO: Replace this field with better method of parsing
    'Generic', //0
    'Cards',
    'Currency',
    'Store',
    'Quality',
    'SplashItems'
];

function addQuery(type, value, bool = null) {
    const isRange = value && typeof value === "object";
    var queryBlock = {
        Type: type,
        ...(value && !isRange && { Value: value }),
        ...(bool && { Bool: bool }),
        ...(isRange && { Range: value }),
    };
    return queryBlock
}

function getRarity() {
    //TODO: Add func
}

function getCurrency(type) {
    const currency = type.currencyType;
    const currencyBlock = {
        Type: "currencyType",
        Value: `${currency}`,
        Format: "number"
    }
    const addBlock = {
        Bool: "range",
        Type: "adjustment",
        Range: {
            Bool: type.Add ? "gt" : "lt",
            Value: "0"
        }
    };
    return [currencyBlock, addBlock];
}

function getStore() {
    //TODO: Add func
}

function getQuality(type) {
    const quality = type.qualityType;
    return {
        Type: "Quality",
        Value: `${quality}`,
        Format: "number"
    }
}

function getItems() {
    //TODO: Add func
}

export default function parseLogQuery(playerId, logTypes, logSettings) {
    //TODO: May need another value to determine data type - string or number?
    const logQueries = [];
    logTypes.map(type => {
        const logSetting = logSettings.find(log => log.LogType === type);
        var logQuery = [];
        var userIdField = logSetting?.PlayerIdLabel ?? "UserID";
        logQuery.push(addQuery(userIdField, playerId))
        logQuery.push(addQuery("LogType.keyword", type.value.split("-")[0], "term"));
        if (type?.Special && type.Special < Special.length) {
            switch (type?.Special) {
                case 1: {
                    logQuery.push(getRarity());
                    break;
                }
                case 2: {
                    logQuery.push(...getCurrency(type));
                    break;
                }
                case 3: {
                    logQuery.push(getStore()); //Pass in store settings
                    break;
                }
                case 4: {
                    logQuery.push(getQuality(type));
                    break;
                }
                case 5: {
                    logQuery.push(getItems()); //Pass in item settings
                    break;
                }
            }
        }
        logQueries.push({
            log: type.label,
            query: logQuery
        });
    });
    return logQueries;
}