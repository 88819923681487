import { useState } from 'react';
import classes from './LoginStubs.module.css';
import Button from 'components/Button';
import fetcher from 'util/fetcher';
import getUrl from 'util/getUrl';
import { toast } from 'react-hot-toast';

function LoginStubs(props) {
    const { stubs, userId, mutate } = props;

    const [selected, setSelected] = useState([])
    const [inputStub, setInputStub] = useState("");
    const [loading, setLoading] = useState(false);

    const updateSelected = stub => {
        if (selected.includes(stub)) setSelected(prev => prev.toSpliced(prev.findIndex(item => item === stub), 1));
        else setSelected(prev => prev.concat(stub));
    }

    async function addStub(newStub) {
        setLoading(true);
        const updatedStub = newStub.replace('=', '_'); //Format consistently with stubs on backend
        const response = await fetcher(`${getUrl()}/UserInfo/AddLoginStub?userId=${userId}&stub=${encodeURIComponent(updatedStub)}`);
        const res = await response.json();
        if (!res?.result) {
            const err = `Error adding login stub: ${res?.error?.message ?? 'N/A'}`
            console.error(err);
            toast.error(`${err}`)
            setLoading(false)
        } else {
            setInputStub("");
            toast.success(`Stub ${newStub} added successfully!`);
            //Update stub list locally for immediate update
            mutate({ stubs: stubs.concat([newStub]) }, false).then(() => {
                setLoading(false);
            });
        }
        setLoading(false);
    }

    async function transferStub(transferStubs) {
        setLoading(true);
        var error = false;
        for (const stub of transferStubs) {
            const response = await fetcher(`${getUrl()}/UserInfo/TransferLoginStub?userId=${userId}&loginStub=${encodeURIComponent(stub)}&transferId=${inputStub}`);
            const res = await response.json();
            if (!res?.success) {
                const err = `Error transferring stub: ${res?.err?.Message ?? 'N/A'}`;
                console.error(err);
                toast.error(`${err}`)
                setLoading(false)
                error = true
                break;
            }
        }
        if (!error) {
            setInputStub("");
            toast.success(`Selected stubs transfered successfully!`);
            await mutate({stubs: stubs.filter(stub => !transferStubs.includes(stub))}, false);
            setLoading(false);
        }
    }

    async function deleteStub(deleteStubs) {
        setLoading(true);
        var error = false;
        for (const stub of deleteStubs) {
            const updatedStub = stub.replace('=', '_'); //Format consistently with stubs on backend
            const response = await fetcher(`${getUrl()}/UserInfo/DeleteLoginStub?userId=${userId}&stub=${encodeURIComponent(updatedStub)}`);
            const res = await response.json();
            if (!res?.result) {
                const err = `Error deleting stub: ${res?.err?.Message ?? 'N/A'}`;
                console.error(err);
                toast.error(`${err}`)
                setLoading(false)
                error = true
                break;
            }
        }
        if (!error) {
            setInputStub("");
            toast.success(`Selected stubs deleted successfully!`);
            await mutate({stubs: stubs.filter(stub => !deleteStubs.includes(stub))}, false);
            setLoading(false);
        }
    }

    return (
        <div
            className={classes.root}
        >
            <div
                className={classes.stubsWrapper}
            >
                {stubs?.map(stub => (
                    <div
                        key={stub}
                        className={classes.stub}
                        onClick={() => updateSelected(stub)}
                    >
                        <input
                            type="checkbox"
                            id={stub}
                            checked={selected.includes(stub)}
                            readOnly
                        ></input>
                        <div className={classes.stubText}>{stub}</div>
                    </div>
                ))}
            </div>
            <div className={classes.notice}>{`Login stubs rely on Elasticsearch; Changes may take time to appear accurately.`}</div>
            <input
                type="text"
                placeholder="Enter a Login Stub or Id"
                value={inputStub}
                onChange={e => setInputStub(e.target.value)}
                className={classes.inputStub}
            ></input>
            <div className={classes.buttonsWrapper}>
                <Button
                    onClick={() => addStub(inputStub)}
                    text="Add"
                    disabled={inputStub.length < 0}
                />
                <Button
                    onClick={() => deleteStub(selected)}
                    text="Delete"
                    disabled={selected.length < 1}
                />
                <Button
                    onClick={() => transferStub(selected)}
                    text="Transfer Selected"
                    disabled={selected.length < 1 || inputStub.length <= 0}
                />
            </div>
        </div>
    );
}

export default LoginStubs;