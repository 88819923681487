import getUrl from 'util/getUrl';
import hasValue from 'util/hasValue';
import fetcher from 'util/fetcher';

export default async (json) => {
    const form = { //Original Ajax call passes object as form, converting to form to keep consistent
        query: json
    };
    const formData = new FormData();
    for (const [key, value] of Object.entries(form)) {
        formData.append(key, value);
    };
    const res = await fetcher(`${getUrl()}/UserInfo/GetStateData`, {
        method: 'POST',
        body: formData
    })
    const data = await res.json();
    if (data.err) return [{err: `Error: ${data.err}`}];
    const values = Object.values(data?.docs)
    let newData = [];
    if (values?.length > 0) {
        newData = values.sort((a, b) => a.Score - b.Score).map(doc => {
            if (hasValue(doc?.CDID)) {
                return {
                    value: doc?.CDID,
                    label: doc?.CDID,
                }
            }
        });
    }
    return newData;
}