import useSWRImmutable from 'swr/immutable';
import getUrl from 'util/getUrl';
import getUrlParam from 'util/getUrlParam';
import fetcher from 'util/fetcher';

export default function useFileData(filename) {
    const game = getUrlParam("game") ?? "phoenix"; //phoenix as fallback TODO: Remove fallback after testing
    const env = getUrlParam("environment");
    const lowerEnv = env?.toLowerCase() ?? "";
    
    //var url = `https://localdev.cdgsrv.com:44323/Settings/GetFile`;
    var url = `https://frontend.cdgsrv.com/Settings/GetFile`;
    var parse = false;
    if (process.env.NODE_ENV === "development") {
        url = `https://localdev.cdgsrv.com:44323/Settings/GetFile`;
    }
    else if (lowerEnv.includes("local") || lowerEnv.includes("development") || lowerEnv.includes("wifi") || game.toLowerCase().includes('unreal')) {
        url = `${getUrl()}/SharedView/GetFileData`;
        parse = true;
    }
    const { data, error, isLoading, isValidating, mutate } = useSWRImmutable(filename ? `${url}?filename=${filename}&game=${game}&env=${env}` : null, (...args) =>
        fetcher(...args).then(res => res?.json()).then(file => parse ? JSON.parse(file.data) : file), {
            errorRetryCount: 3
        });

    return {
        data: data,
        isLoading: isLoading || isValidating,
        isError: error,
        mutate,
    }
}