import { useState, useEffect, useRef } from 'react';
import classes from './ReadText.module.css';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';

function ReadText(props) {

    const {
        label,
        value,
        canEdit = false,
        editName = 'Edit',
        actions = null,
        update, //Function to update value
        boolView = false,
        isLoading = false,
    } = props; //TODO: Add alternate view modes (bool for court pass, editable for player name)

    const [isEditing, setEditing] = useState(false);
    const [localValue, setLocalValue] = useState(value ?? "");

    const inputRef = useRef(null);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    if (isEditing) inputRef.current.focus();

    const updateField = () => {
        update(localValue);
        setEditing(false);
    }

    const cancelChanges = () => {
        setLocalValue(value);
        setEditing(false);
    }

    return (
        <div className={boolView ? classes.boolRoot : classes.root}>
            <div className={boolView ? classes.boolDisplay : classes.display}>
                <label
                    id={`readtext-${label}`}
                    className={classes.label}
                >
                    {label}
                </label>
                {!boolView ?
                    <input
                        ref={inputRef}
                        className={classes.input}
                        id={`readtext-${label}`}
                        value={localValue ?? ""}
                        onChange={(e) => setLocalValue(e.target.value)}
                        readOnly={!isEditing} //Use possible isEditing value for editable type
                    /> :
                    value ? <FontAwesomeIcon icon={faCheck} className={classes.check} /> : <FontAwesomeIcon icon={faX} className={classes.x} />
                }
            </div>
            <div className={classes.actions}>
                {isEditing &&
                    <>
                        <Button
                            onClick={updateField}
                            text={"Save Changes"}
                        />
                        <Button
                            onClick={cancelChanges}
                            text={"Cancel"}
                        />
                    </>
                }
                {!isEditing && canEdit &&
                    <Button
                        onClick={() => setEditing(true)}
                        text={editName}
                        isLoading={isLoading}
                    />
                }
                {actions?.map(action => 
                    <Button
                        key={`readtextaction-${action.text}`}
                        onClick={action.action}
                        text={action.text}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </div>
    );
}

export default ReadText;